* {
  box-sizing: border-box;
  /* border: 1px solid black; */
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6f7f4;
  font-family: Helvetica, sans-serif;
  overflow: hidden;
  letter-spacing: 1px;
}

#root {
  height: 100%;
  width: 100%;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
